angular.module("app")
	.controller("tripReceiptController", ["$log", "$timeout", "$stateParams", "tripService", function($log, $timeout, $stateParams, tripService){
		var vm = this;
		vm.bookingReference = $stateParams.bookingReference;
		vm.organisationReference = $stateParams.organisationReference;
		vm.receipt = undefined;
		vm.loading = false;
		vm.pageNotReady = true;
		
		(function init(){
			loadTrip();
		})();
		
		function loadTrip(){
			vm.loading = true;
			return tripService.getReceipt(vm.organisationReference, vm.bookingReference)
				.then(function(result){
					vm.receipt = result;
				}, function(error) {
					$log.error(error);
				})['finally'](function(){
					vm.pageNotReady = false;
					vm.loading = false;
				});
		}
	}]);